export function CurrentHotLeadWarningBox() {
  const boxStyle = {
    backgroundColor: '#fff8e1',
    border: '1px solid #ffd54f',
    padding: '20px',
    borderRadius: '5px',
    width: '98%',
    margin: '0 0 1rem 0',
    display: ' flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    minWidth: '386px',
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
  };

  const titleStyle = {
    color: 'red',
    fontWeight: 'bold',
    fontSize: '16px',
    marginBottom: '10px',
    textAlign: 'center',
    width: '100%',
  };

  const textStyle = {
    color: '#000',
    fontSize: '14px',
    textAlign: 'left',
  };
  return (
    <div style={boxStyle}>
      <div style={titleStyle}>Hot Lead</div>
      <div style={textStyle}>
        This is a high priority lead and hence a better churn and a timely
        follow-up is expected here. The lead may get reassigned if this is not
        followed.
      </div>
    </div>
  );
}
