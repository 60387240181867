import React, { useState } from 'react';
import { LeadsSearchResults } from './LeadsSearchResults';
import { CurrentLeadModal } from './CurrentLeadModal';
import { useCurrentLead } from '../CurrentLeadContext';

function LeadsDataTable({
  data,
  refreshResults,
  totalLeads,
  currentPage,
  setCurrentPage,
  perPage,
  setPerPage,
  setColumnFilters,
  queryParams = {},
  fetachingLeadData,
  isCalledFromDnp,
  isCalledFromRcb,
  setSortPriority,
  isFromPriorityList = false,
}) {
  const { currentLead, getCurrentLeadData, resetCurrentLeadContext } =
    useCurrentLead();
  const [openLeadDetailsDialog, setLeadDetailsDialog] = useState(false);

  const updateCurrentLead = (leadId) => {
    if (!currentLead || currentLead.lead_id !== leadId) {
      getCurrentLeadData(leadId);
    }
    setLeadDetailsDialog(!openLeadDetailsDialog);
  };

  const handleLeadDetailsDialogClose = () => {
    setLeadDetailsDialog(false);
    resetCurrentLeadContext();
    refreshResults();
  };

  // if (totalLeads && totalLeads !== 0)
  return (
    <>
      <LeadsSearchResults
        isFromPriorityList={isFromPriorityList}
        isCalledFromDnp={isCalledFromDnp}
        isCalledFromRcb={isCalledFromRcb}
        leads={data}
        updateCurrentLead={updateCurrentLead}
        refreshResults={refreshResults}
        totalLeads={totalLeads}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPage={perPage}
        setPerPage={setPerPage}
        setColumnFilters={(filter) => setColumnFilters(filter)}
        queryParams={queryParams}
        fetachingLeadData={fetachingLeadData}
        setSortPriority={setSortPriority}
      />
      {currentLead && openLeadDetailsDialog && (
        <CurrentLeadModal
          {...{ openLeadDetailsDialog, handleLeadDetailsDialogClose }}
        />
      )}
    </>
  );
  // else {
  //   return (
  //     <center style={{ marginTop: 20, marginBottom: 60 }}>
  //       No Leads Found
  //     </center>
  //   );
  // }
}

export default LeadsDataTable;
