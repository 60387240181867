import React, { useCallback, useEffect, useState } from 'react';
import axiosInstance from 'apis/axiosInstance';
import { base_url } from '../Mode';
import {
  Chip,
  Grid,
  IconButton,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tab,
  Tabs,
  Box,
  Checkbox,
  Button,
  CircularProgress,
} from '@mui/material';
import PropTypes from 'prop-types';
import {
  getFormattedTimestamp,
  CopyIcon,
  splitByUnderscoresAndCapitalize,
  currentAdminIsAdmin,
  currentAdminIsManager,
  getTimeZoneFormattedTimestamp,
} from '../../utils/common';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { addDays, getTimePeriod, subtractDays } from '../Sidebar';
import LeadEditForm from './LeadEditForm';
import EditIcon from '@mui/icons-material/Edit';
import { useCurrentLead } from '../CurrentLeadContext';
import PartialViewLeadEditForm from './PartialViewLeadEditForm';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import LeadCommunications from './LeadCommunications';
import ConfettiEffect from './ConfettingEffect';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import { InteractionsList } from './InteractionsList';
import InteractionFileUploads from './InteractionFileUploads/InteractionFileUploads';
import EligibilityFormDialog from './EligibilityFormDialog';
import SuccessNotifier from '../ToastNotifications/SuccessNotifier';
import ExternalTabComponents from './ExternalTabComponents';
import ExternalTabComponentsV1 from './ExternalTabComponentsV1';
import CallButton from '../callAnimation/CallButton';
import { useNavigate } from 'react-router-dom';
import constants from '../../utils/constants';
import { styled } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LeadDropRequestDetails from './LeadDropRequestDetails';
import io from 'socket.io-client';
import { useSocket } from '../../socket/socketContext';
import ForumIcon from '@mui/icons-material/Forum';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import ModeCommentIcon from '@mui/icons-material/ModeComment';
import { useSelector } from 'react-redux';
import { getCurrAdmin } from 'features/auth/authSelector';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export function LeadDetailsModalV1(props) {
  const {
    currentLead,
    setCurrentLead,
    getCurrentLeadInteractionsAndUpdateContext,
    getCurrentLeadAndUpdateContext,
    leadFocusMode,
    currentLeadActivities,
    currentLeadInteractions,
    currentLeadStatusActivities,
    currentLeadSignups,
    getCurrentLeadActivitiesAndUpdateContext,
    getCurrentLeadStatusActivitiesAndUpdateContext,
  } = useCurrentLead();
  const [newInteractionNote, setNewInteractionNote] = React.useState('');
  const [interactionInProgress, setInteractionInProgress] =
    React.useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorText, setErrorText] = React.useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successText, setSuccessText] = React.useState('');
  const [openLeadDetailsDialog, setLeadDetailsDialog] = React.useState(true);
  const [openEditLeadDetailsDialog, setOpenEditLeadDetailsDialog] =
    React.useState(false);
  const [allDropReasons, setAllDropReasons] = useState({});
  const [showConfetties, setShowConfetties] = useState(false);
  const [openFileUploads, setOpenFileUploads] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedEligbilityForm, setSelectedEligibilityForm] = useState([]);
  const [snoozedUntil, setSnoozedUntil] = useState('');
  const [callbackRequestActivity, setCallbackRequestActivity] = useState(false);
  // const [leadDropCheck, setLeadDropCheck] = useState(false);
  const [callDialledActivity, setCallDialledActivity] = useState('');
  const [notifyMe, setNotifyMe] = useState(false);
  const [selectedExternalTab, setSelectedExternalTab] = useState(4);
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const [showActivity, setShowActivity] = useState(true);
  const [expandedTabs, setExpandedTabs] = useState([true, true, true]);
  const [raisedDropRequestDetails, setRaisedDropRequestDetails] = useState([]);
  const [selectedReasons, setSelectedReasons] = useState({
    primary: '',
    secondary: {},
  });
  const [salesClouserPaymentsData, setSalesClouserPaymentsData] =
    useState(null);
  const [showContactDetails, setShowContactDetails] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [callLog, setCallLog] = useState(null);
  const [selectedEfConversionFlow, setSelectedEfConversionFlow] = useState([]);
  const [cpeIsLoading, setCpeIsLoading] = useState(false);

  const { allAdmins } = useSelector((state) => state.admins);

  const currentAdmin = getCurrAdmin();
  const adminMap = {};
  allAdmins.forEach((admin) => (adminMap[admin['id']] = admin));
  const navigate = useNavigate();
  const {
    CREATED,
    ONE_STEP_TO_PAYMENT,
    COMPLETE_AMOUNT_PAID,
    REACTIVATED,
    DROPPED,
    DROPPED_POST_SALES_REFUND_INITIATED,
    DROPPED_POST_SALES_REFUND_DENIED,
    SCREENING_CALL_ATTENDED,
    TOKEN_AMOUNT_PAID,
  } = constants;

  const statusNames = [
    'created',
    'assigned',
    'connect_attempted',
    'answer_and_intro_done',
    'lead_qualification_done',
    'questioning_in_tect_done',
    'complete_program_explained',
    'eligibility_form_sent',
    'eligibility_form_filled',
    'intent_closure_call_done',
    'screening_call_scheduled',
    'screening_call_attended',
    'offer_letter_payment_link_sent',
    'token_amount_paid',
    'complete_amount_paid',
  ];

  let flagformorethanSixDnp = true;
  const ArrayofLeadStatuslength = currentLeadStatusActivities.length;
  const currentLeadStatus =
    currentLeadStatusActivities[ArrayofLeadStatuslength - 1]?.status;
  const indexOfCurrentStatus = statusNames.indexOf(currentLeadStatus);
  if (indexOfCurrentStatus > 6) {
    flagformorethanSixDnp = false;
  }

  // const indexOfCompleteExplained = statusNames.indexOf('complete_program_explained');

  const handleContactDetails = (currentTime, lastSyncTime) => {
    // if (!lastSyncTime) {
    setShowContactDetails(true);
    return;
    // }
    const timeDifference = (currentTime - lastSyncTime) / (1000 * 60);

    if (timeDifference < constants.THIRTY_MINUTES) {
      setShowContactDetails(true);
    } else {
      setShowContactDetails(false);
    }
  };

  const getAppLastSync = async (date) => {
    setIsLoading(true);
    let currentTime;
    try {
      const res = await axiosInstance.get(`/mobile/api/last-sync`);
      const lastSyncTime = new Date(res.data).getTime();
      if (date) {
        currentTime = new Date(date).getTime();
      } else {
        currentTime = new Date().getTime();
      }
      handleContactDetails(currentTime, lastSyncTime);
      setIsLoading(false);
    } catch (error) {
      setHasError(true);
      setErrorText(
        error.response?.data?.message || 'Error: Could not sync with the app.'
      );
    }
  };

  const [socket] = useSocket();

  const filterIcsAndCheck = (message) => {
    if (parseInt(message.id) === currentAdmin.id) {
      getAppLastSync(message.date);
    }
  };

  const handleLastSync = (data) => {
    data.forEach((message) => {
      filterIcsAndCheck(message);
    });
  };

  const initialCheck = () => {
    getAppLastSync();
  };

  useEffect(() => {
    initialCheck();
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on('last_sync', (data) => handleLastSync(data));

      return () => {
        socket.off('last_sync', handleLastSync);
      };
    }
  }, [socket]);

  let dnpSnoozeTime = '';
  useEffect(() => {
    if (showConfetties) {
      setTimeout(() => {
        setShowConfetties(false);
      }, 7000);
    }
  }, [showConfetties]);

  useEffect(() => {
    const fetchDropReasons = () => {
      axiosInstance
        .get(`/api/users/drop_reasons?status=${currentLead.status}`)
        .then((res) => {
          if (res.data) {
            setAllDropReasons(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
          setHasError(true);
          setErrorText(
            err.response?.data?.message || "Couldn't fetch the drop reasons."
          );
        });
    };

    if (currentLead.status) {
      fetchDropReasons();
    }

    return () => {};
  }, [currentLead.status]);

  const updateRequestBody = {
    requested_callback: callbackRequestActivity,
    call_did_not_pick: callDialledActivity,
    notify_for_follow_up: notifyMe,
  };
  const updateRequestBodyForLeaddrop = () => {
    updateRequestBody.assignee = 206;
    updateRequestBody.label = '6th DNP drop';
  };

  useEffect(() => {
    leadDropRequest(currentLead.id);
  }, [currentLead.id]);

  const leadDropRequest = async (id) => {
    try {
      const res = await axiosInstance.get(
        `/api/manager_actions/${id}/drop_request`
      );
      if (res.data !== null) {
        setRaisedDropRequestDetails(res.data);
      }
    } catch (error) {
      console.log(error);
      setHasError(true);
      setErrorText(
        error.response?.data?.message || "Couldn't fetch the drop request."
      );
    }
  };

  const handleCallbackRequest = (status) => {
    setCallbackRequestActivity(status);
    if (status && !notifyMe) {
      handleNotifyMe();
    }
  };

  const handleExpandClick = (index) => {
    const newExpandedTabs = [...expandedTabs];
    newExpandedTabs[index] = !newExpandedTabs[index];
    setExpandedTabs(newExpandedTabs);
  };

  const handleShowActivity = () => {
    setShowActivity(!showActivity);
  };

  // const handleLeadDropCheck = () => {
  //   setLeadDropCheck(!leadDropCheck);
  // };

  const handleCallDialledActivity = (e) => {
    setCallDialledActivity(e.target.value);
  };

  const handleNotifyMe = () => {
    setNotifyMe(!notifyMe);
  };

  const resetValue = () => {
    setInteractionInProgress(false);
    setNewInteractionNote('');
    setUploadedFiles([]);
    setSnoozedUntil('');
    setCallbackRequestActivity(false);
    setNotifyMe(false);
    setCallDialledActivity('');
    // setLeadDropCheck(false);
  };

  const updateCurrentLeadContexts = () => {
    getCurrentLeadActivitiesAndUpdateContext(currentLead.id);
    getCurrentLeadStatusActivitiesAndUpdateContext(currentLead.id);
    getCurrentLeadInteractionsAndUpdateContext(currentLead.lead_id);
    getCurrentLeadAndUpdateContext(currentLead.lead_id);
  };

  const calculateDnpCount = (flagformorethanSixDnp, currentLeadActivities) => {
    let dnpcountoflead = 0;

    if (flagformorethanSixDnp) {
      const filteredLeadActivities = currentLeadActivities.filter(
        (obj) => obj.name !== 'snooze_update'
      );
      filteredLeadActivities?.forEach((obj) => {
        if (obj.name === 'did_not_pick') {
          if (obj.metadata === 'frequency:2') {
            dnpcountoflead += 2;
          } else {
            dnpcountoflead++;
          }
        } else {
          dnpcountoflead = 0;
        }
      });
    }

    return dnpcountoflead;
  };

  const addInteraction = async () => {
    if (uploadedFiles.length > 10) {
      setHasError(true);
      setErrorText('Maximum 10 files can be uploaded');
      return;
    }

    if (
      selectedExternalTab !== 1 &&
      selectedExternalTab !== 3 &&
      !newInteractionNote
    ) {
      setHasError(true);
      setErrorText("Interaction can't be empty");
      resetValue();
      return;
    }

    if (selectedExternalTab === 1 && !callDialledActivity) {
      setHasError(true);
      setErrorText('Please choose a call dial activity');
      resetValue();
      return;
    }

    if (
      snoozedUntil &&
      snoozedUntil < getTimeZoneFormattedTimestamp(new Date())
    ) {
      setHasError(true);
      setErrorText('Please choose future timestamp');
      return;
    }

    // this condition will check if the lead status is after CPE so normal work flow
    if (!flagformorethanSixDnp) {
      if (!snoozedUntil && selectedExternalTab !== 2) {
        setHasError(true);
        setErrorText("Follow up time can't be empty");
        resetValue();
        return;
      }
    }

    const searchDnp = () => {
      const lowercasedNewInteractionNote = newInteractionNote.toLowerCase();
      return lowercasedNewInteractionNote.includes('dnp');
    };

    if (selectedExternalTab !== 1 && newInteractionNote && searchDnp()) {
      setSelectedExternalTab(1);
      return;
    }

    setInteractionInProgress(true);

    const postData = {
      notes: newInteractionNote.trim(),
      lead_id: currentLead.lead_id,
    };

    try {
      if (postData.notes) {
        const response = await axiosInstance.post(
          base_url() + '/api/users/' + currentLead.lead_id + '/interactions',
          postData
        );
        if (response.data?.id && uploadedFiles.length > 0) {
          const formBody = new FormData();
          uploadedFiles.forEach((file) => {
            formBody.append('interaction_files', file.data);
          });
          await axiosInstance.post(
            base_url() +
              '/api/interactions/' +
              response.data.id +
              '/files?context=interaction',
            formBody,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );
        }
      }
      if (snoozedUntil) {
        updateRequestBody.snoozed_until_1 = snoozedUntil;
      }
      if (
        selectedExternalTab === 1 &&
        calculateDnpCount(flagformorethanSixDnp, currentLeadActivities) >= 6 &&
        flagformorethanSixDnp
      ) {
        updateRequestBodyForLeaddrop();
      }
      if (!snoozedUntil && selectedExternalTab === 1 && flagformorethanSixDnp) {
        const currentTime = new Date().getHours();
        let day = 'Today';
        if (currentTime >= 16) {
          day = 'Tomorrow';
        }
        updateSnoozedUntil(day, `${currentTime} PM`);
        updateRequestBody.snoozed_until_1 = dnpSnoozeTime;
      }

      if (callDialledActivity === 'Dialled Once') {
        updateRequestBody.frequency = 1;
      } else {
        updateRequestBody.frequency = 2;
      }
      await axiosInstance.put(
        base_url() + '/api/users/' + currentLead.lead_id,
        updateRequestBody
      );
      setIsSuccess(true);
      {
        selectedExternalTab === 1 &&
        flagformorethanSixDnp === true &&
        calculateDnpCount(flagformorethanSixDnp, currentLeadActivities) >= 6
          ? setSuccessText('Lead reassigned due to exceeding 6 DNP marks')
          : setSuccessText('Updated');
      }
      resetValue();
      updateCurrentLeadContexts();
    } catch (err) {
      console.log(err);
      setHasError(true);
      setErrorText(
        err.response?.data?.message ?? 'Something went wrong, fetch failed'
      );
    } finally {
      resetValue();
      updateCurrentLeadContexts();
      setSelectedExternalTab(4);
    }
  };

  const detailsNotAvailable = () => {
    return (
      <span
        style={{
          color: '#cecece',
        }}
      >
        Unavailable
      </span>
    );
  };

  const detailsHidden = () => {
    return (
      <span
        style={{
          color: '#ffcccb',
        }}
      >
        Hidden
      </span>
    );
  };

  const showContactsDetails = () => {
    return (
      <>
        {isLoading ? (
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 10,
              color: 'grey',
            }}
          >
            {' '}
            <CircularProgress
              sx={{ color: 'silver' }}
              size={20}
            />{' '}
            Loading ...
          </span>
        ) : (
          <span
            style={{
              color: '#ffcccb',
            }}
          >
            Please refresh your app
          </span>
        )}
      </>
    );
  };

  const snoozeDetails = function () {
    if (!currentLead.snoozed_until_1) return detailsNotAvailable();

    return (
      <Stack
        direction={'row'}
        spacing={2}
        alignItems='center'
      >
        <Typography
          p={1}
          sx={{
            backgroundColor: '#fab005',
            width: 'fit-content',
            borderRadius: '10px',
            color: '#fff',
            lineHeight: '1rem',
          }}
          display='flex'
          alignItems='center'
          gap='7px'
          fontSize='1rem'
        >
          {getFormattedTimestamp(new Date(currentLead.snoozed_until_1))}
        </Typography>
        {currentLead.has_scheduled_follow_up && <NotificationsActiveIcon />}
      </Stack>
    );
  };

  const getLinkedinLink = () => {
    if ((currentLead?.linkedin_link ?? '') === '') {
      return detailsNotAvailable();
    } else if (currentLead?.linkedin_link === 'Hidden') {
      return detailsHidden();
    }
    if (!showContactDetails) return showContactsDetails();
    const link = `https://www.linkedin.com/in/${currentLead?.linkedin_link}`;
    return (
      <a
        style={{ marginLeft: 5 }}
        href={link}
        target='_blank'
      >
        {currentLead?.linkedin_link}
      </a>
    );
  };

  function getCollegeText() {
    if (
      !currentLead.branch &&
      !currentLead.graduation_year &&
      !currentLead.college
    ) {
      return detailsNotAvailable();
    }

    let text = '';
    if (currentLead.branch || currentLead.graduation_year) {
      text += currentLead.branch;
      text += ' ';
      text += currentLead.graduation_year;
    }

    if (currentLead.college) {
      text += ' graduate from ' + currentLead.college;
    }

    // required when branch is not present
    text = text.trim();

    // capitalize first char
    text = text.charAt(0).toUpperCase() + text.slice(1);

    return (
      <>
        {text}
        <br />
      </>
    );
  }

  function getEmploymentText() {
    if (!currentLead.experience && !currentLead.role && !currentLead.company) {
      return detailsNotAvailable();
    }

    // There might be cases where the experience is not set to working_professional
    // but the role and company fields are present.
    let text = '';

    if (currentLead.experience != 'working_professional') {
      text += currentLead.experience + '. ';
    }

    if (currentLead.role || currentLead.company) {
      text += 'Works';
      text += currentLead.role ? ' as ' + currentLead.role : '';
      text += currentLead.company ? ' at ' + currentLead.company : '';
    } else if (currentLead.experience == 'working_professional') {
      text += 'Working professional';
    }

    // capitalize first char
    text = text.charAt(0).toUpperCase() + text.slice(1);

    return (
      <>
        {text}
        <br />
      </>
    );
  }

  const getAssigneeText = () => {
    if (!currentLead?.assignee) {
      return (
        <Chip
          label={'No assignee'}
          sx={{
            backgroundColor: '#9575cd',
            color: '#fff',
          }}
        />
      );
    }

    const { fname, lname } = adminMap[currentLead?.assignee];

    return (
      <>
        <Chip
          label={[fname, lname || ''].join(' ')}
          sx={{
            backgroundColor: '#9575cd',
            color: '#fff',
          }}
        />
      </>
    );
  };
  const getProductText = () => {
    return (
      <>
        {currentLead.product && <>Product&nbsp;</>}
        <span>
          {!currentLead.product || currentLead.product === ''
            ? detailsNotAvailable()
            : splitByUnderscoresAndCapitalize(currentLead.product)}
        </span>
      </>
    );
  };

  const getLeadSourceTypeText = () => {
    return (
      <span
        style={{
          color: 'black',
          fontWeight: 400,
          textTransform: 'capitalize',
        }}
      >
        {currentLead?.lead_source_type || detailsNotAvailable()}
      </span>
    );
  };

  let emailCopyBtn = CopyIcon(currentLead.email);
  const getEmailText = () => {
    if (!currentLead.email) return detailsNotAvailable();
    else if (currentLead.email === 'Hidden') return detailsHidden();
    if (!showContactDetails) return showContactsDetails();
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {currentLead.email} {emailCopyBtn}
      </div>
    );
  };

  let MobileCopyBtn = CopyIcon(currentLead.mobile);
  const getMobileText = () => {
    if (!currentLead.mobile) return detailsNotAvailable();
    if (currentLead.mobile === 'Hidden') return detailsHidden();
    if (!showContactDetails) return showContactsDetails();
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          letterSpacing: '2px',
          fontWeight: '500',
          fontSize: '20px',
        }}
      >
        {currentLead.mobile} {MobileCopyBtn}
      </div>
    );
  };

  let whatsappCopyBtn = CopyIcon(currentLead.whatsapp);
  const getWhatsappText = () => {
    if (!currentLead.whatsapp) return detailsNotAvailable();
    if (currentLead.whatsapp === 'Hidden') return detailsHidden();
    if (!showContactDetails) return showContactsDetails();
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          letterSpacing: '2px',
          fontWeight: '500',
          fontSize: '20px',
        }}
      >
        {currentLead.whatsapp} {whatsappCopyBtn}
      </div>
    );
  };

  const getExperienceText = () => {
    if (!currentLead.experience) return detailsNotAvailable();
    return <div>{currentLead.experience}</div>;
  };

  const getTechStackText = () => {
    if (!currentLead.tech_stack) return detailsNotAvailable();
    return <div>{currentLead.tech_stack}</div>;
  };

  const getBranchText = () => {
    if (!currentLead.branch) return detailsNotAvailable();
    return <div>{currentLead.branch}</div>;
  };

  const getGradutionYearText = () => {
    if (!currentLead.graduation_year) return detailsNotAvailable();
    return <div>{currentLead.graduation_year}</div>;
  };

  const getRoleText = () => {
    if (!currentLead.role) return detailsNotAvailable();
    return <div>{currentLead.role}</div>;
  };

  const getCompanyText = () => {
    if (!currentLead.company) return detailsNotAvailable();
    return <div>{currentLead.company}</div>;
  };

  const getCreatedAtDate = () => {
    if (!currentLead.created_at) return detailsNotAvailable();
    return (
      <div>
        {getFormattedTimestamp(new Date(Date.parse(currentLead.created_at)))}
      </div>
    );
  };

  const getLabelInfo = () => {
    if (!currentLead.label) return detailsNotAvailable();
    return <div>{currentLead.label}</div>;
  };

  const openLeadDetailsDialogForm = () => {
    setLeadDetailsDialog(!openLeadDetailsDialog);
    setOpenEditLeadDetailsDialog(!openEditLeadDetailsDialog);
  };

  const handleEditLeadDetailsDialogClose = useCallback((val) => {
    setOpenEditLeadDetailsDialog(val);
    setLeadDetailsDialog(!openLeadDetailsDialog);
  }, []);

  function ProfileDetailsTableView({
    openLeadDetailsDialogForm,
    profileDetails,
    // raisedDropRequestDetails,
  }) {
    function getRow(label, value) {
      if (label === '_divide_') {
        return (
          <TableRow
            sx={{
              border: '2px solid #495057',
              marginTop: '1px',
            }}
          />
        );
      }

      // if (hideFrom.includes(getCurrentAdminRole()) || !value) {
      //   return <></>;
      // }
      return (
        <TableRow key={label}>
          <TableCell
            sx={{
              width: '30%',
              padding: '5px 10px',
              border: '1px solid #e0e0e0',
              fontSize: '1rem',
              p: 1,
            }}
          >
            {label}
          </TableCell>
          <TableCell
            sx={{
              width: '70%',
              padding: '5px 10px',
              border: '1px solid #e0e0e0',
              fontSize: '1rem',
              p: 1,
            }}
          >
            {value}
          </TableCell>
        </TableRow>
      );
    }

    function getEditIcon(openLeadDetailsDialogForm) {
      return (
        <IconButton
          onClick={openLeadDetailsDialogForm}
          size='medium'
          sx={{
            float: 'right',
            mr: 1,
            color: 'black',
            display: 'inline-table',
          }}
          disabled={raisedDropRequestDetails.length !== 0}
        >
          <EditIcon />
        </IconButton>
      );
    }

    const shouldDisplayLeadExiprationText = (currentAdmin, currentLead) => {
      if (
        Object.keys(currentAdmin).length === 0 ||
        currentLead.status === 'dropped'
      ) {
        return false;
      }
      if (currentLead.assignee === currentAdmin.id || currentAdminIsAdmin()) {
        return true;
      }

      const { subordinate_admin_ids = [] } = currentAdmin;
      if (subordinate_admin_ids.includes(currentLead.assignee)) {
        return true;
      }
      return false;
    };

    const LeadExpirationLabel = (props) => {
      const properties = {
        label:
          "This lead's lifecycle is over. You can continue to work on it but the lead may get unassigned anytime.",
        color: 'red',
      };
      const now = new Date();
      let leadAssignedTimestamp = undefined;
      let leadExpirationTimestamp = undefined;

      props.currentLeadActivities.forEach((activity) => {
        if (activity.name === 'assignee_update' && activity.metadata !== null) {
          leadAssignedTimestamp = new Date(Date.parse(activity.created_at));
          leadExpirationTimestamp = addDays(leadAssignedTimestamp, 10);
        }
      });

      if (leadAssignedTimestamp) {
        if (subtractDays(leadExpirationTimestamp, 3) > now) {
          properties.color = 'blue';
          properties.label = `This lead will be unassigned in ${getTimePeriod(
            leadExpirationTimestamp,
            now
          )} days`;
        } else if (
          subtractDays(leadExpirationTimestamp, 3) <= now &&
          now < subtractDays(leadExpirationTimestamp, 1)
        ) {
          const period = getTimePeriod(leadExpirationTimestamp, now);
          properties.color = '#f57c00'; // orange
          properties.label = `This lead will be unassigned in ${period} day${
            period > 1 ? 's' : ''
          }`;
        } else if (
          subtractDays(leadExpirationTimestamp, 1) <= now &&
          now <= leadExpirationTimestamp
        ) {
          const period = getTimePeriod(leadExpirationTimestamp, now, true);
          if (period >= 1) {
            // greater than 1 hour
            properties.label = `This lead will be unassigned in ${Math.floor(
              period
            )} hours`;
          } else if (period >= 0.0166) {
            // greater than 1 minute, but less than 1 hour
            properties.label = `This lead will be unassigned in ${Math.floor(
              period * 60
            )} minutes`;
          } else if (period > 0) {
            // greater than 0 seconds, but less than 1 minute
            properties.label = `This lead will be unassigned in ${Math.floor(
              period * 60 * 60
            )} seconds`;
          }
        }
      }

      return (
        <>
          {leadAssignedTimestamp && now >= leadAssignedTimestamp ? (
            <Stack
              direction='row'
              spacing={0.5}
              sx={{ color: properties.color, mb: 1 }}
            >
              <AccessTimeIcon />
              <Typography
                varian
                t='body1'
                component='p'
              >
                {properties.label}
              </Typography>
            </Stack>
          ) : (
            <></>
          )}
        </>
      );
    };

    return (
      <Table sx={{ width: '90%' }}>
        <TableHead>
          <TableRow sx={{ border: '1px solid #e0e0e0' }}>
            <TableCell
              colSpan={2}
              display={'flex'}
              justifyContent='space-between'
            >
              {showContactDetails ? (
                <span>
                  <Typography
                    fontSize={'20px'}
                    display={'inline-table'}
                  >
                    Profile
                  </Typography>
                  {getEditIcon(openLeadDetailsDialogForm)}
                </span>
              ) : (
                'Please refresh your app'
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {shouldDisplayLeadExiprationText(currentAdmin, currentLead) && (
            <TableRow sx={{ border: '1px solid #e0e0e0' }}>
              <TableCell
                sx={{
                  border: '1px solid #e0e0e0',
                  fontSize: '1rem',
                }}
              >
                Lifecycle
              </TableCell>
              <TableCell
                display={'flex'}
                justifyContent='space-between'
              >
                <Typography
                  fontSize={'20px'}
                  display={'inline-table'}
                >
                  <LeadExpirationLabel
                    currentLead={currentLead}
                    currentLeadActivities={currentLeadActivities}
                  />
                </Typography>
              </TableCell>
            </TableRow>
          )}
          {profileDetails.map((row) => getRow(row.label, row.value))}
        </TableBody>
      </Table>
    );
  }

  const interactionPlaceholderText =
    'You can highlight text using a colon.\nEg: "Current CTC: 5 LPA" will highlight "Current CTC" in a different color';
  let copyText = [];
  if (currentLead.fname) {
    copyText.push(currentLead.fname);
  }
  if (currentLead.lname) {
    copyText.push(currentLead.lname);
  }

  copyText.push('#' + currentLead.lead_id);
  copyText = copyText.join(' ');
  let LeadIdCopyIcon = CopyIcon(copyText);

  const profileDetails = [
    {
      label: 'Email',
      value: getEmailText(),
    },
    {
      label: 'Phone',
      value: getMobileText(),
    },
    {
      label: 'Whatsapp',
      value: getWhatsappText(),
    },
    {
      label: 'Linkedin',
      value: getLinkedinLink(),
    },
    {
      label: '_divide_',
    },
    {
      label: 'Experience',
      value: getExperienceText(),
    },
    {
      label: 'Tech stack',
      value: getTechStackText(),
    },
    {
      label: 'Branch',
      value: getBranchText(),
    },
    {
      label: 'Graduation year',
      value: getGradutionYearText(),
    },
    {
      label: 'Role',
      value: getRoleText(),
    },
    {
      label: 'Company',
      value: getCompanyText(),
    },
    {
      label: 'College',
      value: getCollegeText(),
    },
    {
      label: '_divide_',
    },
    {
      label: 'Created at',
      value: getCreatedAtDate(),
    },
    {
      label: 'Lead source type',
      value: getLeadSourceTypeText(),
    },
    {
      label: 'Next follow up',
      value: snoozeDetails(),
    },
    {
      label: 'Product',
      value: getProductText(),
    },
    {
      label: 'Label',
      value: getLabelInfo(),
    },
    {
      label: 'Assigned to',
      value: getAssigneeText(),
    },
  ];

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const handleExternalTabs = (_, newValue) => {
    setSelectedExternalTab(newValue);
  };

  const updateSnoozedUntil = (day, value) => {
    let date = new Date();

    // Check if the selected tab is for interactions
    if (selectedExternalTab === 1 && flagformorethanSixDnp === true) {
      switch (day) {
        case 'Today':
          if (new Date().getHours() < 13) {
            date.setHours(16);
            date.setMinutes(0);
          } else if (new Date().getHours() < 16) {
            date.setHours(19);
            date.setMinutes(0);
          } else {
            if (date.getDay() === 1 && date.getHours() === 16) {
              // Check if it's Monday and time is 16:00
              date.setDate(date.getDate() + 2);
            } else {
              date.setDate(date.getDate() + 1);
            }
            date.setHours(13);
            date.setMinutes(0);
          }
          break;

        case 'Tomorrow':
          date.setDate(date.getDate() + 1);
          date.setHours(13);
          date.setMinutes(0);
          break;

        default:
          date = new Date(value);
          break;
      }
    } else {
      // If selected tab is not for interactions, set snooze time as per usual flow
      switch (day) {
        case 'Today':
          switch (value) {
            case '12 PM':
              date.setMinutes(0);
              date.setHours(12);
              break;
            case '3 PM':
              date.setMinutes(0);
              date.setHours(15);
              break;
            case '6 PM':
              date.setMinutes(0);
              date.setHours(18);
              break;
            case '9 PM':
              date.setMinutes(0);
              date.setHours(21);
              break;
          }
          break;

        case 'Tomorrow':
          switch (value) {
            case '9 AM':
              date.setMinutes(0);
              date.setDate(date.getDate() + 1);
              date.setHours(9);
              break;
            case '12 PM':
              date.setMinutes(0);
              date.setDate(date.getDate() + 1);
              date.setHours(12);
              break;
            case '6 PM':
              date.setMinutes(0);
              date.setDate(date.getDate() + 1);
              date.setHours(18);
              break;
            case '9 PM':
              date.setMinutes(0);
              date.setDate(date.getDate() + 1);
              date.setHours(21);
              break;
          }
          break;

        default:
          date = new Date(value);
          break;
      }
    }
    dnpSnoozeTime = getTimeZoneFormattedTimestamp(date);
    setSnoozedUntil(getTimeZoneFormattedTimestamp(date));
  };

  const callButtonClickHandler = () => {
    const leadId = currentLead.id;
    navigate(`/leads/${leadId}/call`, {
      state: {
        currentLead,
        currentLeadStatusActivities,
        currentLeadSignups,
        currentLeadInteractions,
      },
    });
  };

  const getCPEDetailsHandler = async () => {
    try {
      setCpeIsLoading(true);
      const leadId = currentLead.id;
      if (!leadId) {
        setHasError(true);
        setErrorText('Unable to fetch CPE Details');
        setCpeIsLoading(false);
        return;
      }

      const res = await axiosInstance.get(`/api/get_cpe_details/${leadId}`);

      if (res.data) {
        setIsSuccess(true);
        setSuccessText(
          res.data.data.length > 0
            ? `CPE Details fetched Successfully...`
            : `CPE details is not present for this lead...`
        );
        let updatedCurrentLead = currentLead;
        updatedCurrentLead.cpeDetail = res.data.data;
        setCurrentLead(updatedCurrentLead);
      }

      setCpeIsLoading(false);
    } catch (error) {
      setCpeIsLoading(true);
      setHasError(true);
      setErrorText('Error occured while fetching CPE details');
      console.log('Error occured while fetching CPE details ', error.message);
    }
  };

  const callLogsHandler = async () => {
    setIsLoading(true);
    try {
      const leadId = currentLead.id;

      if (!leadId) {
        setHasError(true);
        setErrorText('Unable to fetch call logs lead id not found');
        setIsLoading(false);
        return;
      }

      const res = await axiosInstance.get(`/api/call_logs/${leadId}`);

      if (res.data) {
        setCallLog(res.data);
      }

      setIsLoading(false);
    } catch (error) {
      setHasError(true);
      setErrorText(
        `Unable to fetch call logs lead id not found ${
          error.response?.data?.message ?? ' '
        }`
      );
      setIsLoading(false);
    }
  };

  const excludedStatuses = [
    CREATED,
    ONE_STEP_TO_PAYMENT,
    COMPLETE_AMOUNT_PAID,
    REACTIVATED,
    DROPPED,
    DROPPED_POST_SALES_REFUND_INITIATED,
    DROPPED_POST_SALES_REFUND_DENIED,
  ];

  const shouldShowButton =
    !excludedStatuses.includes(currentLead.status) &&
    (currentAdminIsAdmin() || currentAdminIsManager());

  const isTimeToChangePositonOfCallingButton =
    currentLead.status === SCREENING_CALL_ATTENDED;
  return (
    <>
      {showConfetties && <ConfettiEffect />}
      {hasError && (
        <ErrorNotifier
          message={errorText}
          setHasError={setHasError}
        />
      )}
      {isSuccess && (
        <SuccessNotifier
          message={successText}
          setIsSuccess={setIsSuccess}
        />
      )}
      {openFileUploads && (
        <InteractionFileUploads
          {...{
            openFileUploads,
            setOpenFileUploads,
            uploadedFiles,
            setUploadedFiles,
          }}
        />
      )}

      {selectedEligbilityForm.length > 0 && (
        <EligibilityFormDialog
          {...{
            selectedEligbilityForm,
            setSelectedEligibilityForm,
            selectedEfConversionFlow,
            setSelectedEfConversionFlow,
          }}
        />
      )}

      <Grid
        container
        spacing={1}
        p={2}
        pr={0}
        height={'fit-content'}
        style={{ overflow: 'hidden' }}
      >
        <Grid
          item
          xs={4}
          style={{
            borderRight: '2px solid grey',
            height: '85vh',
            overflow: 'auto',
            paddingBottom: leadFocusMode ? '160px' : '70px',
          }}
        >
          {!openEditLeadDetailsDialog && (
            <Stack spacing={1}>
              <Typography
                variant='h5'
                fontWeight={500}
                mb={2}
                color='error'
              >
                {currentLead.is_blacklisted
                  ? 'This lead is not supposed to be contacted'
                  : ''}
              </Typography>

              <ProfileDetailsTableView
                openLeadDetailsDialogForm={openLeadDetailsDialogForm}
                profileDetails={profileDetails}
                //   raisedDropRequestDetails={raisedDropRequestDetails}
              />
            </Stack>
          )}

          {openEditLeadDetailsDialog ? (
            <LeadEditForm
              openModal={openEditLeadDetailsDialog}
              setOpenModal={handleEditLeadDetailsDialogClose}
              setShowConfetties={setShowConfetties}
              allDropReasons={allDropReasons}
              setAllDropReasons={setAllDropReasons}
            />
          ) : (
            <>
              <LeadCommunications />
            </>
          )}
        </Grid>

        <Grid
          item
          xs={8}
          style={{
            height: leadFocusMode ? '56vh' : '85vh',
            overflow: 'auto',
          }}
        >
          <Stack
            spacing={2}
            sx={{
              padding: '10px 10px',
              position: 'relative',
            }}
          >
            {shouldShowButton && (
              <Box
                sx={{
                  position: 'absolute',
                  mt: isTimeToChangePositonOfCallingButton
                    ? ''
                    : '2rem !important',
                }}
                onClick={callButtonClickHandler}
              >
                <CallButton />
              </Box>
            )}
            {raisedDropRequestDetails.length === 0 &&
            raisedDropRequestDetails !== null ? (
              <>
                <PartialViewLeadEditForm
                  salesClouserPaymentsData={salesClouserPaymentsData}
                  setSalesClouserPaymentsData={setSalesClouserPaymentsData}
                  setShowConfetties={setShowConfetties}
                  allDropReasons={allDropReasons}
                  setAllDropReasons={setAllDropReasons}
                  raisedDropRequestDetails={raisedDropRequestDetails}
                  selectedReasons={selectedReasons}
                  setSelectedReasons={setSelectedReasons}
                  leadDropRequest={leadDropRequest}
                />
                <Box>
                  {selectedExternalTab == 4 && (
                    <Box
                      sx={{
                        marginTop: 0,
                        padding: 2,
                        backgroundColor: '#EBF8FF',
                        borderBottomLeftRadius: '5px',
                        borderBottomRightRadius: '5px',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-around',
                        }}
                      >
                        <Box
                          sx={{
                            boxShadow: 1,
                            width: '23rem',
                            height: '3rem',
                            bgcolor: (theme) =>
                              theme.palette.mode === 'dark'
                                ? '#101010'
                                : '#fff',
                            color: (theme) =>
                              theme.palette.mode === 'dark'
                                ? 'grey.300'
                                : 'grey.800',
                            p: 1,
                            m: 1,
                            borderRadius: 2,
                            textAlign: 'center',
                            display: 'flex',
                            direction: 'row',
                            cursor: 'pointer',
                          }}
                          onClick={() => setSelectedExternalTab(0)}
                        >
                          <ForumIcon
                            sx={{
                              marginTop: '0.4rem',
                              marginLeft: '1rem',
                            }}
                          />
                          <Typography
                            sx={{
                              marginTop: '0.3rem',
                              fontWeight: 500,
                              marginLeft: '1rem',
                              fontSize: '1.1rem',
                            }}
                          >
                            Detailed Interaction
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            boxShadow: 1,
                            width: '23rem',
                            height: '3rem',
                            bgcolor: (theme) =>
                              theme.palette.mode === 'dark'
                                ? '#101010'
                                : '#fff',
                            color: (theme) =>
                              theme.palette.mode === 'dark'
                                ? 'grey.300'
                                : 'grey.800',
                            p: 1,
                            m: 1,
                            borderRadius: 2,
                            textAlign: 'center',
                            display: 'flex',
                            direction: 'row',
                            cursor: 'pointer',
                          }}
                          onClick={() => setSelectedExternalTab(1)}
                        >
                          <PhoneMissedIcon
                            sx={{
                              marginTop: '0.4rem',
                              marginLeft: '1rem',
                            }}
                          />
                          <Typography
                            sx={{
                              marginTop: '0.3rem',
                              fontWeight: 500,
                              marginLeft: '1rem',
                              fontSize: '1.1rem',
                            }}
                          >
                            Did not pick (DNP)
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-around',
                        }}
                      >
                        <Box
                          sx={{
                            boxShadow: 1,
                            width: '23rem',
                            height: '3rem',
                            bgcolor: (theme) =>
                              theme.palette.mode === 'dark'
                                ? '#101010'
                                : '#fff',
                            color: (theme) =>
                              theme.palette.mode === 'dark'
                                ? 'grey.300'
                                : 'grey.800',
                            p: 1,
                            m: 1,
                            borderRadius: 2,
                            textAlign: 'center',
                            display: 'flex',
                            direction: 'row',
                            cursor: 'pointer',
                          }}
                          onClick={() => setSelectedExternalTab(3)}
                        >
                          <PhoneCallbackIcon
                            sx={{
                              marginTop: '0.4rem',
                              marginLeft: '1rem',
                            }}
                          />
                          <Typography
                            sx={{
                              marginTop: '0.3rem',
                              fontWeight: 500,
                              marginLeft: '1rem',
                              fontSize: '1.1rem',
                            }}
                          >
                            Requested Callback (RCB)
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            boxShadow: 1,
                            width: '23rem',
                            height: '3rem',
                            bgcolor: (theme) =>
                              theme.palette.mode === 'dark'
                                ? '#101010'
                                : '#fff',
                            color: (theme) =>
                              theme.palette.mode === 'dark'
                                ? 'grey.300'
                                : 'grey.800',
                            p: 1,
                            m: 1,
                            borderRadius: 2,
                            textAlign: 'center',
                            display: 'flex',
                            direction: 'row',
                            cursor: 'pointer',
                          }}
                          onClick={() => setSelectedExternalTab(2)}
                        >
                          <ModeCommentIcon
                            sx={{
                              marginTop: '0.4rem',
                              marginLeft: '1rem',
                            }}
                          />
                          <Typography
                            sx={{
                              marginTop: '0.3rem',
                              fontWeight: 500,
                              marginLeft: '1rem',
                              fontSize: '1.1rem',
                            }}
                          >
                            Add Interaction without a call
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  )}

                  {selectedExternalTab !== 4 && (
                    <ExternalTabComponentsV1
                      salesClouserPaymentsData={salesClouserPaymentsData}
                      setSalesClouserPaymentsData={setSalesClouserPaymentsData}
                      flagformorethansixdnp={flagformorethanSixDnp}
                      selectedExternalTab={selectedExternalTab}
                      setSelectedExternalTab={setSelectedExternalTab}
                      handleCallbackRequest={handleCallbackRequest}
                      callbackRequestActivity={callbackRequestActivity}
                      newInteractionNote={newInteractionNote}
                      setNewInteractionNote={setNewInteractionNote}
                      updateSnoozedUntil={updateSnoozedUntil}
                      snoozedUntil={snoozedUntil}
                      handleNotifyMe={handleNotifyMe}
                      notifyMe={notifyMe}
                      handleCallDialledActivity={handleCallDialledActivity}
                      addInteraction={addInteraction}
                      interactionPlaceholderText={interactionPlaceholderText}
                      setOpenFileUploads={setOpenFileUploads}
                      interactionInProgress={interactionInProgress}
                      openFileUploads={openFileUploads}
                      callDialledActivity={callDialledActivity}
                      uploadedFiles={uploadedFiles}
                      // leadDropCheck={leadDropCheck}
                      // handleLeadDropCheck={handleLeadDropCheck}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'end',
                    textAlign: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                    width: '100%',
                    padding: '0 20px 0 0',
                  }}
                >
                  {!showActivity &&
                    (currentAdminIsAdmin() || currentAdminIsManager()) && (
                      <Button onClick={callLogsHandler}>Load Call Logs</Button>
                    )}
                  {!showActivity && (
                    <Button
                      onClick={getCPEDetailsHandler}
                      disabled={cpeIsLoading}
                    >
                      {isLoading ? 'Fetching...' : 'Load CPE Details'}
                    </Button>
                  )}
                  <Checkbox
                    sx={{ marginY: 1, padding: 0 }}
                    {...label}
                    onChange={handleShowActivity}
                    checked={showActivity}
                  />
                  <Typography
                    sx={{
                      marginLeft: '10px',
                      fontSize: '15px',
                    }}
                  >
                    Show external activities only
                  </Typography>
                </Box>
              </>
            ) : (
              <>
                {raisedDropRequestDetails.length > 0 &&
                  Object.keys(allDropReasons).length > 0 && (
                    <LeadDropRequestDetails
                      raisedDropRequestDetails={raisedDropRequestDetails}
                      {...{
                        reasons: allDropReasons,
                        selectedReasons,
                        setSelectedReasons,
                        leadDropRequest,
                      }}
                    />
                  )}
              </>
            )}
            {showActivity ? (
              <InteractionsList
                adminMap={adminMap}
                setHasError={setHasError}
                setErrorText={setErrorText}
                setSelectedEligibilityForm={setSelectedEligibilityForm}
                setSelectedEfConversionFlow={setSelectedEfConversionFlow}
                showActivity={showActivity}
              />
            ) : (
              <InteractionsList
                callLog={callLog}
                adminMap={adminMap}
                setHasError={setHasError}
                setErrorText={setErrorText}
                setSelectedEligibilityForm={setSelectedEligibilityForm}
                setSelectedEfConversionFlow={setSelectedEfConversionFlow}
                showActivity={showActivity}
              />
            )}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}

export const getTechStackProperties = (type) => {
  let color = '';
  let tooltipMessage = '';

  if (type === 'tech_background') {
    color = 'green';
    tooltipMessage = 'Lead has a tech background';
  } else if (type === 'non_tech_background') {
    color = 'red';
    tooltipMessage = 'Lead does not have a tech background';
  } else {
    color = 'gray';
    tooltipMessage =
      "Information required to verify the lead's tech background is missing";
  }

  return { color, tooltipMessage };
};
